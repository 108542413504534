import { setupLayouts } from 'virtual:generated-layouts'
import type { App } from "vue"
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from "vue-router/auto-routes"


const filtered = routes[0]!.children!.filter(it => it.meta?.namespace == "frontend")
for (const route of filtered) {
  route.meta = { layout: "frontend/layouts/default", ...route.meta }
}

const routesWithLayout = setupLayouts(filtered)
const router = createRouter({
  history: createWebHistory(),
  routes: routesWithLayout,
})

export default function(app: App) {
  app.use(router)
  app.config.globalProperties.$router = router as any
}
