import { createApp } from 'vue'
import App from './App.vue'

import "reflect-metadata"

const app = createApp(App)

import * as initializers from "./initializers"
for (const key in initializers) {
  initializers[key](app)
}

app.mount("#app")
